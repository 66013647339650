type WorkerEventDataType = {
    name: string;
    result: string;
};

export const runTaskInWorker = async (
    actionName: string,
    ...args: string[]
) => {
    return new Promise<string>((resolve) => {
        const worker = new Worker(`${process.env.PUBLIC_URL}/worker.js`);
        worker.onmessage = ({ data }: MessageEvent<WorkerEventDataType>) => {
            const { name, result } = data;
            if (name === actionName) {
                resolve(result);
            }
            worker.terminate();
        };
        worker.postMessage({
            name: actionName,
            args,
        });
    });
};
