import { Component, ReactNode } from 'react';

type StateType = {
    hasError: boolean;
    errorMessage?: string;
};

type PropsType = {
    onError: (errorMessage: string) => void;
    children?: ReactNode;
};

export class ErrorBoundary extends Component<PropsType, StateType> {
    componentDidCatch({ message, stack }: Error) {
        this.props.onError(stack ?? message);
    }

    render() {
        return this.props.children;
    }
}
