import { EditorPropsType } from '../type';
import { highlight } from '../../../lib/highlight';

const noop = () => {};

export const useEditor = ({
    value,
    onValueChange,
    ...restProps
}: EditorPropsType) => {
    return {
        rootProps: restProps,
        editorProps: {
            value: value ?? '',
            onValueChange: onValueChange ?? noop,
            highlight: (code: string) => highlight(code, 'tsx'),
        },
    };
};
