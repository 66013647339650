import { FC } from 'react';
import { CircularProgress } from '@mui/material';

import { PlaygroundPropsType } from './type';
import {
    PlaygroundRoot,
    PlaygroundTop,
    PlaygroundBottom,
    PlaygroundError,
} from './style';
import { usePlayground } from './hooks/usePlayground';
import { Editor } from '../Editor';
import { ErrorBoundary } from '../ErrorBoundary';

export const Playground: FC<PlaygroundPropsType> = (props) => {
    const {
        rootProps,
        editorProps,
        errorBoundaryProps,
        Component,
        compilationError,
        loading,
    } = usePlayground(props);

    return (
        <PlaygroundRoot {...rootProps}>
            <PlaygroundTop>
                <Editor {...editorProps} />
            </PlaygroundTop>
            <PlaygroundBottom>
                <ErrorBoundary {...errorBoundaryProps}>
                    {!loading && Component && <Component />}
                    {!loading && compilationError && (
                        <PlaygroundError>{compilationError}</PlaygroundError>
                    )}
                    {loading && <CircularProgress />}
                </ErrorBoundary>
            </PlaygroundBottom>
        </PlaygroundRoot>
    );
};
