import { FC } from 'react';
import { Container, Typography, Box } from '@mui/material';
import styled from '@emotion/styled';
import { Playground } from '../components';

const HomeRoot = styled(Container)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const HomeBody = styled.div`
    flex: 1 0 auto;
`;

export const Home: FC = () => {
    return (
        <HomeRoot maxWidth="lg">
            <Box paddingY={3}>
                <Typography variant="h4">React playground</Typography>
            </Box>
            <HomeBody>
                <Playground />
            </HomeBody>
        </HomeRoot>
    );
};
