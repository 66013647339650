import { FC } from 'react';
import { Box } from '@mui/material';

import { LayoutPropsType } from './type';
import { useLayout } from './hooks/useLayout';

export const Layout: FC<LayoutPropsType> = ({ children, ...props }) => {
    const { rootProps } = useLayout(props);

    return (
        <Box height="100%" {...rootProps}>
            {children}
        </Box>
    );
};
