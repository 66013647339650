import prism from 'prismjs';
import 'prismjs/components/prism-css.js';
import 'prismjs/components/prism-diff.js';
import 'prismjs/components/prism-javascript.js';
import 'prismjs/components/prism-json.js';
import 'prismjs/components/prism-jsx.js';
import 'prismjs/components/prism-markup.js';
import 'prismjs/components/prism-tsx.js';

import 'prismjs/themes/prism-coy.css';

export const highlight = (code: string, language: string) => {
    let grammar;
    let prismLanguage = '';

    if (language === 'tsx' || language === 'ts') {
        grammar = prism.languages.tsx;
        prismLanguage = 'typescript';
    } else if (language === 'jsx' || language === 'js') {
        grammar = prism.languages.jsx;
        prismLanguage = 'javascript';
    }

    if (!grammar) {
        return code;
    }

    return prism.highlight(code, grammar, prismLanguage);
};
