import { ComponentType, useCallback, useEffect, useState } from 'react';
import { debounce } from 'throttle-debounce';

import { PlaygroundPropsType } from '../type';
import { execute } from '../../../lib/execute';

const defaultValue = `import { Stack, Button } from '@mui/material';

export default function BasicButtons() {
  return (
    <Stack spacing={2} direction="row">
      <Button variant="text">Text</Button>
      <Button variant="contained">Contained</Button>
      <Button variant="outlined">Outlined</Button>
    </Stack>
  );
}
`;

export const usePlayground = (props: PlaygroundPropsType) => {
    const [value, setValue] = useState(defaultValue);

    const [loading, setLoading] = useState(true);

    const [compilationResult, setCompilationResult] = useState<{
        component: ComponentType<unknown>;
    } | null>(null);
    const [compilationError, setCompilationError] = useState('');

    const onEditorValueChangeDebounced = useCallback(
        debounce(500, (nextValue: string) => {
            execute(nextValue).then(({ error, component }) => {
                if (error) {
                    setCompilationError(error);
                    setCompilationResult(null);
                } else {
                    setCompilationError('');
                    setCompilationResult({ component });
                }
                setLoading(false);
            });
        }),
        [],
    );

    useEffect(() => {
        onEditorValueChangeDebounced(defaultValue);
    }, [onEditorValueChangeDebounced]);

    return {
        rootProps: props,
        editorProps: {
            value: value,
            onValueChange: (nextValue: string) => {
                setLoading(true);
                setValue(nextValue);
                onEditorValueChangeDebounced(nextValue);
            },
        },
        errorBoundaryProps: {
            onError: (error: string | null) => {
                setCompilationResult(null);
                setCompilationError(error ?? 'An error occurred');
            },
        },
        Component: compilationResult ? compilationResult.component : null,
        compilationError,
        loading,
    };
};
