import { FC } from 'react';
import SimpleEditor from 'react-simple-code-editor';

import { useEditor } from './hooks/useEditor';
import { EditorRoot } from './style';
import { EditorPropsType } from './type';

const editorStyles = {
    fontFamily: '"Fira code", "Fira Mono", monospace',
    fontSize: 12,
};

export const Editor: FC<EditorPropsType> = ({ children, ...restProps }) => {
    const { rootProps, editorProps } = useEditor(restProps);

    return (
        <EditorRoot {...rootProps}>
            <SimpleEditor {...editorProps} padding={10} style={editorStyles} />
        </EditorRoot>
    );
};
