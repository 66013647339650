import styled from '@emotion/styled';

import { PlaygroundRootPropsType } from './type';

export const PlaygroundRoot = styled.div<PlaygroundRootPropsType>`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const PlaygroundTop = styled.div`
    flex-basis: 50%;
    max-height: 50%;
`;

export const PlaygroundBottom = styled.div`
    flex-basis: 50%;
    border: 1px solid grey;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PlaygroundError = styled.div`
    color: #f33333;
    max-width: 80%;
`;
