import { requireModule } from './requireModule';
import { evalInContext } from './evalInContext';
import { compile } from './compile';

type ResultType = {
    component: any;
    error: string;
};

export const execute = async (code: string): Promise<ResultType> => {
    try {
        const compiledCode = await compile(code);

        const exports: any = {};
        const evalCode = evalInContext(compiledCode, requireModule, exports);
        evalCode();

        return {
            component: exports.default,
            error: '',
        };
    } catch (error) {
        const { message, stack } = error as Error;
        return {
            component: null,
            error: stack ?? message,
        };
    }
};
