import styled from '@emotion/styled';

import { EditorRootPropsType } from './type';

export const EditorRoot = styled.div<EditorRootPropsType>`
    box-sizing: border-box;
    background-color: rgba(41, 40, 52, 0.94);
    color: rgba(255, 255, 255, 0.91);
    margin: 0;
    overflow-x: auto;
    height: 100%;
`;
