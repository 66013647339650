import React from 'react';
import * as MUIExports from '@mui/material';

// @ts-expect-error A module without default export should have one
React.default = React;

const moduleMap: Record<string, Record<string, any>> = {
    react: React,
    '@mui/material': MUIExports,
};

export const requireModule = (moduleName: string) => {
    if (!(moduleName in moduleMap)) {
        return null;
    }

    return moduleMap[moduleName];
};
